<template>
  <div
    class="top_title_common"
    :class="currentIndex != 0 ? 'active_Menu_title' : ''"
  >
    <div class="display_logo_name">
      <img src="../../assets/login/top_logo.png" alt="" />
      <span
        :style="{ color: currentIndex == 0 ? colorActive : colorActiveOther }"
        >E</span
      >
      <span class="" :style="{ color: currentIndex != 0 ? '#fff' : '' }"
        >drive</span
      >
    </div>
    <ul class="ul_title">
      <li
        v-for="(item, index) in liNameList"
        :key="index"
        :class="currentIndex == index ? activeColorName : ''"
        @click="clickMenuItem(index)"
        :style="{ color: index == 0 ? colorActive : '' }"
      >
        {{ item }}
      </li>
    </ul>
    <div class="right_phone">
      <span class="top_name">服务热线（仅收市话费）</span>
      <span>416 - 427 - 9758</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopMenu",
  data() {
    return {
      liNameList: [
        "首页",
        "精品课程",
        "G1笔试",
        "证件翻译",
        "常见问题",
        "关于我们",
        "教练专区",
      ],
      colorActive: "",
      colorActiveOther: "#96EF1D",
      currentIndex: null,
      activeColorName: "",
    };
  },
  watch: {
    $route: {
      // immediate: true,
      deep: true,
      handler() {
        if (this.$route.name == "index") {
          this.currentIndex = 0;
          this.clickMenuItem(this.currentIndex);
        } else if (this.$route.name == "premiumCourses") {
          this.currentIndex = 1;
          this.clickMenuItem(this.currentIndex);
        } else if (this.$route.name == "gOneExamination") {
          this.currentIndex = 2;
          this.clickMenuItem(this.currentIndex);
        } else if (this.$route.name == "documentTranslation") {
          this.currentIndex = 3;
          this.clickMenuItem(this.currentIndex);
        } else if (this.$route.name == "commonProblem") {
          this.currentIndex = 4;
          this.clickMenuItem(this.currentIndex);
        } else if (this.$route.name == "aboutUs") {
          this.currentIndex = 5;
          this.clickMenuItem(this.currentIndex);
        } else if (this.$route.name == "coachZone") {
          this.currentIndex = 6;
          this.clickMenuItem(this.currentIndex);
        } else {
          this.currentIndex = null;
          this.colorActiveOther = "#96EF1D";
          this.colorActive = "";
        }
      },
    },
  },
  created() {},
  methods: {
    // 点击上方Menu进行跳转
    clickMenuItem(index) {
      this.currentIndex = index;
      if (this.currentIndex == 0) {
        this.activeColorName = "indexColor";
        this.colorActive = "#7EADFF";
        if (this.$router.history.current.fullPath == "/") return;
        this.$router.push({ path: "/" });
      } else {
        this.activeColorName = "activeColor";
        this.colorActive = "";
      }
      if (index == 1) {
        if (this.$router.history.current.fullPath == "/premiumCourses") return;
        this.$router.push({ path: "/premiumCourses" });
      } else if (index == 2) {
        if (this.$router.history.current.fullPath == "/gOneExamination") return;
        this.$router.push({ path: "/gOneExamination" });
      } else if (index == 3) {
        if (this.$router.history.current.fullPath == "/documentTranslation")
          return;
        this.$router.push({ path: "/documentTranslation" });
      } else if (index == 4) {
        if (this.$router.history.current.fullPath == "/commonProblem") return;
        this.$router.push({ path: "/commonProblem" });
      } else if (index == 5) {
        if (this.$router.history.current.fullPath == "/aboutUs") return;
        this.$router.push({ path: "/aboutUs" });
      } else if (index == 6) {
        if (this.$router.history.current.fullPath == "/coachZone") return;
        this.$router.push({ path: "/coachZone" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.top_title_common {
  width: 100%;
  height: 100px;
  padding: 26px 8%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .display_logo_name {
    display: flex;
    align-items: center;
    z-index: 1000;

    img {
      width: 49px;
      margin-right: 10px;
    }

    span {
      font-family: "Roboto-Medium";
      font-weight: 500;
      font-size: 30px;
      color: #2a2e43;
      font-weight: 500;
    }

    .title_left_E {
      color: #7eadff;
    }
  }
}

.active_Menu_title {
  background-color: rgba(80, 79, 79, 0.6);
  z-index: 10;

  .ul_title {
    li {
      color: #fff;
    }

    .activeColor {
      color: #7eadff;
    }
  }

  .right_phone {
    color: #fff;
  }
}

.top_title_common::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
}

.ul_title {
  display: flex;
  align-items: center;
  list-style-type: none;
  width: 548px;
  justify-content: space-between;
  cursor: pointer;
  z-index: 1000;

  li {
    font-family: "Source Han Sans CN, Source Han Sans CN";
    font-weight: bold;
    font-size: 16px;
    color: #2a2e43;
  }

  // .active_title {
  //     color: #7EADFF;
  // }

  .indexColor {
    color: #7eadff;
  }

  .activeColor {
    color: #96ef1d;
  }
}

.right_phone {
  display: flex;
  flex-direction: column;
  font-family: "Source Han Sans CN, Source Han Sans CN";
  font-weight: bold;
  font-size: 18px;
  color: #2a2e43;
  z-index: 1000;

  .top_name {
    font-size: 12px;
    font-weight: Regular;
  }
}
</style>
