import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/registerDriver",
    name: "registerDriver",
    component: () => import("../views/registerDriver.vue"),
  },
  {
    path: "/password",
    name: "password",
    component: () => import("../views/password.vue"),
  },
  {
    path: "/registerCoach",
    name: "registerCoach",
    component: () => import("../views/registerCoach.vue"),
  },
  {
    path: "/premiumCourses",
    name: "premiumCourses",
    component: () => import("../views/premiumCourses.vue"),
  },
  {
    path: "/gOneExamination",
    name: "gOneExamination",
    component: () => import("../views/gOneExamination.vue"),
  },
  {
    path: "/documentTranslation",
    name: "documentTranslation",
    component: () => import("../views/documentTranslation.vue"),
  },
  {
    path: "/commonProblem",
    name: "commonProblem",
    component: () => import("../views/commonProblem.vue"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("../views/aboutUs.vue"),
  },
  {
    path: "/studentEdit",
    name: "studentEdit",
    component: () => import("../views/studentEditInfor.vue"),
  },
  {
    path: "/coachZone",
    name: "coachZone",
    component: () => import("../views/coachZone.vue"),
  },
  {
    path: "/courseDetail",
    name: "courseDetail",
    component: () => import("../views/courseDetail.vue"),
  },
  {
    path: "/studentCenter",
    name: "studentCenter",
    component: () => import("../views/studentCenter.vue"),
  },
  {
    path: "/goodsDetail",
    name: "goodsDetail",
    component: () => import("../views/goodsDetail.vue"),
  },
  {
    path: "/coachDetail",
    name: "coachDetail",
    component: () => import("../views/coachDetail.vue"),
  },
  {
    path: "/chooseCoach",
    name: "chooseCoach",
    component: () => import("../views/chooseCoach.vue"),
  },
  {
    path: "/registrationSuccess",
    name: "registrationSuccess",
    component: () => import("../views/registrationSuccess.vue"),
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: () => import("../views/paySuccess.vue"),
  },
  {
    path: "/ratingDetails",
    name: "ratingDetails",
    component: () => import("../views/ratingDetails.vue"),
  },
  {
    path: "/coachRatingRoad",
    name: "coachRatingRoad",
    component: () => import("../views/coachRatingRoad.vue"),
  },
  {
    path: "/coachRatingRoadView",
    name: "coachRatingRoadView",
    component: () => import("../views/coachRatingRoadView.vue"),
  },
  {
    path: "/viewDetails",
    name: "viewDetails",
    component: () => import("../views/viewDetails.vue"),
  },
  {
    path: "/coachCenter",
    name: "coachCenter",
    component: () => import("../views/coachCenter.vue"),
  },

  {
    path: "/lessonManagement",
    name: "lessonManagement",
    component: () => import("../views/lessonManagement.vue"),
  },
  {
    path: "/drivingtestManagement",
    name: "drivingtestManagement",
    component: () => import("../views/drivingtestManagement.vue"),
  },
  {
    path: "/accountActivity",
    name: "accountActivity",
    component: () => import("../views/accountActivity.vue"),
  },
  {
    path: "/changePassword",
    name: "changePassword",
    component: () => import("../views/changePassword.vue"),
  },
  {
    path: "/scoreDetailsCoach",
    name: "scoreDetailsCoach",
    component: () => import("../views/scoreDetailsCoach.vue"),
  },
  {
    path: "/scoreDetailsDriver",
    name: "scoreDetailsDriver",
    component: () => import("../views/scoreDetailsDriver.vue"),
  },
  {
    path: "/scoreDetails2",
    name: "scoreDetails2",
    component: () => import("../views/scoreDetails2.vue"),
  },
  {
    path: "/addClock",
    name: "addClock",
    component: () => import("../views/addClock.vue"),
  },
  {
    path: "/additionalExpenses",
    name: "additionalExpenses",
    component: () => import("../views/additionalExpenses.vue"),
  },
  {
    path: "/driverRatingRoad",
    name: "driverRatingRoad",
    component: () => import("../views/driverRatingRoad.vue"),
  },
  {
    path: "/driverRoadScoreDetail",
    name: "driverRoadScoreDetail",
    component: () => import("../views/driverRoadScoreDetail.vue"),
  },
  {
    path: "/coachOfficialRate",
    name: "coachOfficialRate",
    component: () => import("../views/coachOfficialRate.vue"),
  },
  {
    path: "/driverOfficialRate",
    name: "driverOfficialRate",
    component: () => import("../views/driverOfficialRate.vue"),
  },
  {
    path: "/officialRateDetail",
    name: "officialRateDetail",
    component: () => import("../views/officialRateDetail.vue"),
  },
  {
    path: "/driverChangePassword",
    name: "driverChangePassword",
    component: () => import("../views/driverChangePassword.vue"),
  },
  {
    path: "/coachEditInfor",
    name: "coachEditInfor",
    component: () => import("../views/coachEditInfor.vue"),
  },
  {
    path: "/problemsDetail",
    name: "problemsDetail",
    component: () => import("../views/problemsDetail.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); // 将滚动位置设置为顶部
  next();
});

export default router;
